// extracted by mini-css-extract-plugin
export var androidLogo = "index-module--androidLogo--MTpmt";
export var appleLogo = "index-module--appleLogo--ThsSB";
export var carousel = "index-module--carousel--40bOu";
export var container = "index-module--container--+MMgw";
export var content = "index-module--content--+X+Cu";
export var contentContainer = "index-module--contentContainer--q10cp";
export var header = "index-module--header--PBJ1O";
export var imageContainer = "index-module--imageContainer--taCTb";
export var logos = "index-module--logos--8KO5+";
export var screenshot = "index-module--screenshot--SH09-";
export var screenshotBG = "index-module--screenshotBG--kGaEW";
export var screenshotContainer = "index-module--screenshotContainer--l9LZz";